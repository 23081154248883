import DialogueBox from 'app/components/DialogueBox';
import { error, success } from 'app/components/toast';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { InspectionJobModel } from '../../modules/auth/models/JobsModel';
import * as auth from '../../modules/auth/redux/AuthRedux';
import {
  getBuildingList,
  getClients,
  getJobsListingWithDetails,
  publishReport,
  removeJob,
} from '../../modules/auth/redux/jobs/JobsCRUD';

import LoaderComman from 'app/components/shared/loader.component';
import { Badge } from 'primereact/badge';
import { ROUTES } from '../../../constants/routes';
import JobDataTable from '../../components/data-table/customDataTable';
import { BUILDING_DETAILS, GET_ALL_JOBS } from '../../modules/auth/redux/jobs/actions';
import { buildingsArgs } from '../buildings/Buildings';
import { clientsArgs } from '../profile/Clients';
import { CalendarView } from './CalendarView';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

export const jobsArgs = {
  sortProperty: 'createdAt',
  sortOrder: -1,
  offset: 0,
  limit: 1000,
  query: {
    critarion: {
      active: true,
      jobOpened: true,
    },
    fields: '',
  },
};

export const specificJobArgs = {
  critarion: {
    _id: '',
  },
  addedby: '_id email name',
  lastModifiedBy: '_id email name',
};

export const detailedJobsArgs = {
  sortproperty: 'createdAt',
  sortorder: 1,
  offset: 0,
  limit: 10,
  pageNum: 1,
  search: '',
  query: {
    critarion: {
      active: true,
      client: '',
      building: '',
    },
    multipleSearchValues: null,
    clientFields: 'clientName VATNumber companyRegisterationNo active',
    clientRepresentativeFields: 'clientRepresentativeName',
    facilityManagerFields: 'facilityManagerName',
    opperativesFields: 'operativeRefNo operativeName active',
    buildingFields:
      'buildingReference buildingName buildingAddress testRegime testDate testStatus inspectionJobs buildingAssets buildingLocation',
    addedby: '_id email name',
    lastModifiedBy: '_id email name',
  },
};

export interface IJobsProps {
  jobs: InspectionJobModel[];
}

interface Permsissions {
  view: boolean;
  edit: boolean;
}

const Jobs = ({ permsissions }: { permsissions: Permsissions; }) => {
  interface ClientFilterProps {
    name: string;
    value: string;
  }

  interface FMFilterProps {
    name: string;
    value: string;
  }

  const filterData = [
    { name: 'Logged', checked: true, value: 'logged' },
    { name: 'Assigned', checked: true, value: 'assigned' },
    { name: 'Scheduled', checked: true, value: 'scheduled' },
    { name: 'Signed Off', checked: true, value: 'signedoff' },
    { name: 'Closed', checked: true, value: 'closed' }
  ];

  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState<any>([]);
  const [sortProperty, setSortProperty] = useState({
    opened: true,
    client: '',
  });
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortField, setSortField] = useState('createdAt');
  const [clientFilter, setClientFilter] = useState<any>(null);
  const [buildingFilter, setBuildingFilter] = useState<any>(null);
  const [buildings, setBuildings] = useState<any>([]);
  const [clients, setClients] = useState<any>([]);
  const [publishModelVisible, setPublishModelVisible] = useState<boolean>(false);
  const [recipiantsEmail, setRecipiantsEmail] = useState<string>('');
  const [pubJob, setPubJob] = useState<any>(null);
  const [renewalDate, setRenewalDate] = useState<string>('');
  const [summaryNotes, setSummaryNotes] = useState<string>('');
  const [tableView, setTableView] = useState<boolean>(true);
  const [dialogueBox, setDialogueBox] = useState<any>({ flag: false, record: {} });
  const [totalRecords, setTotalRecords] = useState(0);
  const [firstRow, setFirstRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isTableLoading, setTableLoading] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [isChanged, setChanged] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<any>(filterData.map((x: any) => x.value));

  const dispatch = useDispatch();
  const { building: buildingDetails } = useSelector((state: any) => state.job);
  const history = useHistory();

  const emailForm = useFormik({
    initialValues: {
      recipientEmails: [],
      jobid: '',
      reportLibraryIds: [],
      summaryNotes: '',
      scheduleDate: '',
    },
    validationSchema: Yup.object({
      recipientEmails: Yup.array().of(
        Yup.string().email('Invalid email address').required('Required')
      ),
      summaryNotes: Yup.string().required('Required'),
      scheduleDate: Yup.date().required('Required').min(new Date(), 'Date must be in the future'),
    }),
    onSubmit: async (values: any, { setSubmitting }) => {
      setSubmitting(true);
      const data = {
        recipientEmails: values.recipientEmails,
        jobid: pubJob?._id,
        reportLibraryIds: values.reportLibraryIds,
        summaryNotes: values.summaryNotes,
        scheduleDate: values.scheduleDate,
      };
    },
  });

  const sortBy = (event: any) => {
    const { value } = event.target;
    if (value == 1) {
      setSortOrder(1);
    } else {
      setSortOrder(-1);
    }
  };

  const FilterBy = (event: any) => {
    const { value, name } = event.target;
    if (name === 'status') {
      setSortProperty({
        ...sortProperty,
        opened: value === 'false' ? false : true,
      });
    } else if (name === 'client') {
      setSortProperty({
        ...sortProperty,
        client: value,
      });
    } else {
      fetchJobs(false);
    }
  };

  const getFilters = (args: any) => {
    if (buildingFilter) {
      args.query.critarion.building = buildingFilter;
    } else {
      delete args.query.critarion.building;
    }
    if (clientFilter) {
      args.query.critarion.client = clientFilter;
    } else {
      delete args.query.critarion.client;
    }

    return args;
  };

  const fetchJobs = async (filter: boolean = false, isSet: boolean = true) => {
    detailedJobsArgs.pageNum = pageNumber;
    detailedJobsArgs.limit = pageSize;
    detailedJobsArgs.sortorder = sortOrder;
    detailedJobsArgs.sortproperty = sortField;
    detailedJobsArgs.search = filterValue;
    detailedJobsArgs.query.multipleSearchValues = selectedFilter;

    const args = getFilters(detailedJobsArgs);
    args.sortorder = sortOrder;
    args.query.critarion.active = true;

    const response = await getJobsListingWithDetails(args);

    setTableLoading(false);
    setTotalRecords(response.data.totalRecords);
    setJobs([]);
    const results: any = response.data.inspectionJobs.map((x: any, i: number) => {
      return {
        ...x,
        serial: i + 1,
        buildingName: x.buildings ? x.buildings.buildingName : '',
        clients: x.client ? x.client.clientName : '',
        facilityManagerName: x?.facilityManager ? x?.facilityManager?.facilityManagerName : '',
        operativeName:
          x.operatives && x.operatives.length
            ? x.operatives.map((y: any) => y.operativeName).join(', ')
            : '',
      };
    });
    if (filter) {
      setJobs(results);
      setLoading(false);
      return;
    } else {
      dispatch({ type: GET_ALL_JOBS, payload: results });
    }

    if (isSet) {
      setJobs(results);
      setLoading(false);
    }
    return results;
  };

  const fetchBuildings = async () => {
    const args = buildingsArgs;
    args.sortorder = sortOrder;
    args.offset = 0;
    const res = await getBuildingList(args);
    if (buildingDetails) {
      const building = res.data.buildings.find((item: any) => item._id === buildingDetails._id);
      setBuildingFilter({
        buildingName: building.buildingName,
        _id: building._id,
      });
    }
    return res.data.buildings;
  };

  const fetchClients = async () => {
    const clientsData = await getClients(clientsArgs);
    return clientsData.data.clients;
  };

  const onRemoveJob = async (id: string) => {
    const data = { id };
    const response = await removeJob(data);
    if (response.status === 'Success') {
      success('Job removed successfully');
      setTableLoading(true);
      await getAllData();
    }
  };

  useEffect(() => {
    getAllData();
    return () => {
      dispatch({
        type: BUILDING_DETAILS,
        payload: null,
      });
    };
  }, []);

  const getAllData = async () => {
    const [jobs, buildings, clients] = await Promise.all([
      fetchJobs(),
      fetchBuildings(),
      fetchClients(),
    ]);
    setJobs(jobs);
    setBuildings(buildings);
    setClients(clients);
    setLoading(false);
    setTableLoading(false);
  };

  const onEditJob = (job: InspectionJobModel) => {
    dispatch(auth.actions.editJob(job));
    history.push({
      pathname: `${ROUTES.ADMINISTRATION_JOBS_EDIT}/${job._id}`,
      search: '',
      state: { editJob: job },
    });
  };

  const onChangeFilter = async (option: any, name: string) => {
    let val = option?.target.value;

    if (option) {
      if (name === 'building') {
        setBuildingFilter(val);
      } else if (name === 'client') {
        setClientFilter(val);
      }
    } else {
      name === 'building' ? setBuildingFilter(null) : setClientFilter(null);
      const data = await fetchJobs();
      setJobs(data);
    }
    setChanged(true);
  };

  const onChangeEmailInput = (event: any) => {
    const { value } = event.target;
    setRecipiantsEmail(value);
  };

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onPostPublishReport = async (e: any) => {
    setLoading(true);
    const isValidEmail = validateEmail(recipiantsEmail);
    if (!isValidEmail) {
      error('Please enter valid email');
      return;
    }
    const obj = {
      inspectionJob: pubJob?._id,
      client: pubJob?.client,
      clientRepresentative: pubJob?.clientRepresentative,
      facilityManager: pubJob?.facilityManager,
      building: pubJob?.building?._id,
      dateRenewalDate: renewalDate,
      attachment: '/uploads/reportlibuplds/defualt.png',
      archived: false,
      recipinantsEmail: recipiantsEmail,
    };
    const res = await publishReport(obj);
    if (res.status === 'Success') {
      setPublishModelVisible(false);
      setLoading(false);
      success(`Report published successfully.`);
    } else {
      setLoading(false);
      error(res.message);
    }
  };

  const onSwitchView = () => {
    setTableView(!tableView);
  };

  useEffect(() => {
    if (isChanged) {
      fetchJobs(true);
    }
  }, [buildingFilter, clientFilter]);

  useEffect(() => {
    if (isChanged) {
      fetchJobs(false, true);
    }
  }, [pageNumber, pageSize, sortField, sortOrder]);

  const onPageNumberChange = (e: any) => {
    setChanged(true);
    let newPageNumber = e.page + 1;
    if (pageNumber !== newPageNumber || pageSize !== e.rows) {
      setFirstRow(e.first);
      setPageNumber(newPageNumber);
      setPageSize(e.rows);
      setTableLoading(true);
    }
  };

  useEffect(() => {
    if (isChanged) {
      const timeoutId = setTimeout(() => {
        setTableLoading(true);
        fetchJobs(true);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [filterValue, selectedFilter]);

  const onGlobalFilterChange = (e: any) => {
    setChanged(true);
    setFilterValue(e.target.value.trim());
  };

  const onSortChange = (e: any) => {
    setChanged(true);
    setSortOrder(e.sortOrder);
    setSortField(e.sortField);
    setTableLoading(true);
  };

  const onEdit = (id: string) => {
    history.push({
      pathname: `${ROUTES.ADMINISTRATION_JOBS_EDIT}/${id}`,
      search: '',
    });
  };

  const onArchive = (id: string) => {
    setDialogueBox({ flag: true, record: id });
  };

  const capitalizeFirstLetter = (str: string) => {
    if (typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const statusBodyTemplate = (rowData: any) => {

    let color;

    switch (rowData?.jobStatus) {
      case "closed":
        color = "#073b4c";
        break;
      case "scheduled":
        color = "#ff6600";
        break;
      case "assigned":
        color = "#118ab2";
        break;
      case "logged":
        color = "#ffd166";
        break;
      case "signedoff":
        color = "darkgreen";
        break;
      default:
        color = "black"; // Default to orange for other cases
        break;
    }
    return <Badge
      className='h-auto py-1'
      style={{
        background: color,
        width: '100%',
        textAlign: 'center',
        textTransform: 'capitalize',
        fontSize: '13px',
        borderRadius: '50px',
        fontWeight: '400'
      }} value={capitalizeFirstLetter(rowData?.jobStatus) || 'N/A'} />;
  };

  const clearFilter = () => {
    setFilterValue('');
  };

  const jobColumns = [
    {
      field: 'jobReference',
      header: 'Job Reference',
      sortable: true,
      style: { minWidth: '150px' },
    },
    {
      field: 'certificateNo',
      header: 'Certificate Reference',
      sortable: true,
      style: { minWidth: '185px' },
    },
    {
      field: 'buildingName',
      header: 'Building',
      sortable: true,
      style: { minWidth: '200px' },
    },
    {
      field: 'clients',
      header: 'Profile',
      sortable: true,
      style: { minWidth: '200px' },
    },
    {
      field: 'facilityManagerName',
      header: 'Facility Manager',
      sortable: true,
      style: { minWidth: '250px' },
    },
    {
      field: 'jobType',
      header: 'Job Type',
      sortable: true,
      style: { minWidth: '120px' },
    },
    {
      field: 'operativeName',
      header: 'Operative',
      sortable: true,
      style: { minWidth: '130px' },
      //body: operativesBodyTemplate,
    },
    {
      field: 'jobStatus',
      header: 'Status',
      body: statusBodyTemplate,
      style: { minWidth: '100px' },
    },
  ];

  return (
    <LoaderComman isRender={!loading}>
      <div className='card card-custom card-stretch gutter-b'>
        {tableView ? (
          <div className='table-view card-body pt-0 pb-3'>
            <JobDataTable
              dataKey='_id'
              data={jobs}
              columns={jobColumns}
              isLoading={isTableLoading}
              isShowPaginator
              isShowPagSize
              rowCount={pageSize}
              totalRecords={totalRecords}
              firstRow={firstRow}
              onPageChange={onPageNumberChange}
              onGlobalFilterChange={onGlobalFilterChange}
              filterValue={filterValue}
              onSort={onSortChange}
              sortOrder={sortOrder}
              sortField={sortField}
              isShowActionColumns
              onEdit={onEdit}
              onArchive={onArchive}
              clearFilterValue={clearFilter}
              filterData={filterData}
              onFilterDataChange={(e: any) => {
                setChanged(true);
                setSelectedFilter(e.value);
              }}
              selectedFilter={selectedFilter}
            />
          </div>
        ) : (
          <div className='calendar-view card-body pt-5 border-top pb-3'>
            <CalendarView data={jobs} />
          </div>
        )}
        <Modal
          className='modal-sticky modal-sticky-lg modal-sticky-top-center'
          id='publishReport'
          role='dialog'
          data-backdrop='false'
          aria-hidden='true'
          tabIndex='-1'
          show={publishModelVisible}
          animation={false}
        >
          <CardContainer>
            <form onSubmit={emailForm.handleSubmit}>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  Create Report
                </h5>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-active-light-primary'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={() => setPublishModelVisible(false)}
                >
                  <i className='fas fa-times text-xl' />
                </button>
              </div>
              <div className='modal-body'>
                <div className='form-group row'>
                  <div className='col-lg-12'>
                    <label htmlFor='schedule' className='col-form-label'>
                      Report Summary
                    </label>
                    <div className='col-lg-12 col-xl-12'>
                      <textarea
                        className='form-control'
                        rows={3}
                        defaultValue={''}
                        placeholder='Report summary'
                        onChange={(e) => {
                          emailForm.setFieldValue('summaryNotes', e.target.value);
                        }}
                      />
                      {emailForm.errors.summaryNotes && (
                        <div className='text-danger'>
                          {emailForm.errors.summaryNotes.toString()}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <label htmlFor='schedule' className='col-form-label'>
                      Renewal Schedule Date
                    </label>
                    <div className='col-lg-12 col-xl-12'>
                      <input
                        type='date'
                        placeholder='Schedule Date'
                        className='form-control'
                        {...emailForm.getFieldProps('scheduleDate')}
                      />
                      {emailForm.errors.scheduleDate && (
                        <div className='text-danger'>
                          {emailForm.errors.scheduleDate.toString()}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <label htmlFor='schedule' className='col-form-label'>
                      Report Recipinants Email
                    </label>
                    <div className='col-lg-12 col-xl-12'>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-active-light-primary'
                        onClick={() =>
                          emailForm.setFieldValue('recipientEmails', [
                            ...emailForm.values.recipientEmails,
                            '',
                          ])
                        }
                      >
                        <i className='fas fa-plus text-xl' />
                      </button>
                      {emailForm.values.recipientEmails.length &&
                        emailForm.values.recipientEmails.map((email: any, index: number) => (
                          <div className='d-flex flex-row align-items-center justify-content-between gap-1'>
                            <div className='d-flex flex-column align-items-start justify-content-start gap-1'>
                              <input
                                key={index}
                                type='email'
                                placeholder='Enter Recipinants Email'
                                className='form-control'
                                {...emailForm.getFieldProps(`recipientEmails[${index}]`)}
                              />
                              {emailForm.errors.recipientEmails && (
                                <div className='text-danger'>
                                  {emailForm.errors.recipientEmails.toString()}
                                </div>
                              )}
                            </div>
                            <button
                              type='button'
                              className='btn btn-sm btn-icon btn-active-light-danger'
                              onClick={() =>
                                emailForm.setFieldValue(
                                  'recipientEmails',
                                  emailForm.values.recipientEmails.filter(
                                    (_: any, i: number) => i !== index
                                  )
                                )
                              }
                            >
                              <i className='fas fa-times text-xl' />
                            </button>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <div className='col-lg-12'>
                  <div className='d-flex flex-row gap-2'>
                    <button className='btn btn-primary' type='submit'>
                      Create Report
                    </button>
                    <button
                      className='btn btn-danger'
                      type='button'
                      onClick={() => setPublishModelVisible(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </CardContainer>
        </Modal>

        <DialogueBox
          title={'Job Remove'}
          message={'Do you want  to remove job?'}
          state={dialogueBox.flag}
          onClick={() => onRemoveJob(dialogueBox.record)}
          handleClose={() => setDialogueBox({ flag: false, record: {} })}
        />
      </div>
    </LoaderComman>
  );
};

export default Jobs;
