import { getClients } from 'app/modules/auth/redux/jobs/JobsCRUD';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import LoaderComman from 'app/components/shared/loader.component';
import { SET_CLIENTS, SET_PAGE_SIZE, SET_SCROLL_POS, SET_TOTAL_RECORDS } from 'app/modules/auth/redux/client';
import { ROUTES } from 'constants/routes';
import debounce from 'lodash.debounce';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import logoImg from '../../../assets/associate.png';

const StyledImage = styled.img`
  width: 25px;
  height: 25px;
  object-fit: contain;
  border-radius: 50%;
`;

export const clientsArgs = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  pageNum: 1,
  search: '',
  limit: 10,
  query: {
    critarion: {
      active: true,
    },
    fields: 'clientName',
  },
};

export const clientsDetailedArgs = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  pageNum: 1,
  search: '',
  limit: 1000,
  query: {
    critarion: {
      active: true,
    },
    fields: 'clientName',
  },
};

export const clientRepresentativeArgs = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  limit: 1000,
  query: {
    critarion: {
      active: true,
    },
    fields: '',
  },
};

export interface AssociationProps {
  logoPath: File;
  writeup: string;
}

export interface CreateClientProps {
  // user: string;
  clientName: string;
  // businessName: string
  website: string;
  companyRegisterationNo: string;
  VATNumber: string;
  logoPath: string;
  themeColor: string;
  fontColor?: string;
  associations: any[];
  clientReference: string;
  address: string;
  clientEmail: string;
  phoneNumber: string;
  buildings?: [];
  rubberStamp: '';
}

export interface UserProps {
  _id: string;
  name: string;
  email: string;
}

export const clientDetailedArgs = {
  critarion: {
    _id: '',
  },
  clientUserFields: '_id email name',
  addedby: '_id email name',
  lastModifiedBy: '_id email name',
};

const Clients = () => {


  const { clients, rPageSize, rPrevScrollPos, rTotalRecords } = useSelector((state: any) => state.client);

  const scrollDivRef = useRef<any>(null);

  const [clientsData, setClientsData] = useState<any[]>(clients);
  const [isLoading, setLoading] = useState<boolean>(!clients?.length ? true : false);
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortProperty, setSortProperty] = useState<string>('businessName');

  const [totalRecords, setTotalRecords] = useState(rTotalRecords);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(rPageSize);
  const [filterValue, setFilterValue] = useState('');
  const [isChanged, setChanged] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(rPrevScrollPos);

  const history = useHistory();
  const dispatch = useDispatch();

  const getClientsListing = async (isStore = true) => {
    clientsArgs.query.fields = '';
    clientsArgs.query.critarion.active = true;
    clientsArgs.sortorder = sortOrder;
    clientsArgs.sortproperty = sortProperty;
    clientsArgs.pageNum = pageNumber;
    clientsArgs.limit = pageSize;
    clientsArgs.search = filterValue;

    const response = await getClients(clientsArgs);
    const activeClients = response.data.clients;
    activeClients.map((cl: any, i: number) => {
      cl.serial = i + 1;
    });
    if (isStore) {
      dispatch({
        type: SET_TOTAL_RECORDS,
        payload: response.data.totalRecords ? response.data.totalRecords : 0,
      });
    }
    setTotalRecords(response.data.totalRecords);
    setLoading(false);
    return activeClients || [];
  };

  const getAllData = async (isStore = true) => {

    const data = await getClientsListing(isStore);
    setClientsData(data);
    setLoading(false);

    if (isStore) {
      dispatch({
        type: SET_CLIENTS,
        payload: data,
      });
    }
  };

  useEffect(() => {
    if (!clientsData?.length) {
      getAllData();
    }

    if (scrollDivRef.current) {
      scrollDivRef.current.scrollTo(0, prevScrollPos); // Adjust the x and y values as needed
    }
  }, []);

  useEffect(() => {
    if (isChanged) {
      const timeoutId = setTimeout(() => {
        setLoading(true);
        getAllData(false);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [filterValue]);

  useEffect(() => {
    const handleScroll = debounce((e: any) => {
      const scrollContainer = e.target;
      const currentScrollPos = e.target.scrollTop;
      const maxScrollPos = scrollContainer.scrollHeight - scrollContainer.clientHeight;

      if ((currentScrollPos > rPrevScrollPos) && (currentScrollPos >= (maxScrollPos - 1))) {
        setChanged(true);
        setPrevScrollPos(currentScrollPos);
      }
      dispatch({
        type: SET_SCROLL_POS,
        payload: currentScrollPos,
      });
    }, 100);

    const scrollContainer = document.querySelector('.card-height');
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [prevScrollPos]);

  useEffect(() => {
    if (prevScrollPos && isChanged) {
      setPageSize(pageSize + 10);
    }
  }, [prevScrollPos]);

  useEffect(() => {
    if (pageSize && isChanged) {
      dispatch({
        type: SET_PAGE_SIZE,
        payload: pageSize,
      });

      setLoading(true);
      getAllData();
    }
  }, [pageSize]);

  const ProfilePic = styled.img`
  max-width: 230px;
  height: 150px;
  border-radius: 3px;
  object-fit: contain;
`;

  return (
    <LoaderComman isRender={!isLoading}>
      <div className='h-100'>
        <div className='row'>
          <div className='col-lg-12 mb-3'>
            <div className='card'>
              <div className='card-body py-4'>
                <div className='d-lg-flex justify-content-between'>
                  <div className='form-label text-muted d-flex align-items-end'>Total Operatives: &nbsp;<h2 className='mb-0'>{totalRecords}</h2></div>
                  <InputText
                    value={filterValue}
                    onChange={(e) => {
                      setChanged(true);
                      setFilterValue(e.target.value);
                    }}
                    placeholder='Search...'
                    className='form-control w-lg-50 w-xl-25' />
                </div>
              </div>
            </div>

          </div>
        </div>
        <div ref={scrollDivRef} className='card-height' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
          <div className='row'>
            {
              clientsData?.length ?
                clientsData.map((x: any) => {
                  return (
                    <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6 my-3'>
                      <div className="card h-100">
                        <Card title={x.clientName}
                          onClick={() => history.push(`${ROUTES.CLIENT_EDIT}${x._id}`)}
                          className='h-100'
                        >

                          <div className='d-flex justify-content-between'>
                            <p className="m-0 p-0">{x.clientReference}</p>
                          </div>

                          {x.logoPath ? (
                            <div className='avatar-preview mt-6 text-center'>
                              <ProfilePic
                                src={
                                  x.logoPath && typeof x.logoPath !== 'string'
                                    ? URL.createObjectURL(x.logoPath)
                                    : process.env.REACT_APP_IMAGE_URL + x.logoPath
                                }
                                alt='Logo image'
                              />
                            </div>
                          ) : (
                            <ProfilePic src={logoImg} alt='' />
                          )}
                        </Card>
                      </div>
                    </div>
                  );
                })
                :
                <div className='col-lg-12'>
                  <div className='card'>
                    <div className='card-body h5 text-muted'>
                      No records found.
                    </div>
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
    </LoaderComman>
  );
};

export default Clients;
