export const SET_SHOW_ACTIVITY = 'SET_SHOW_ACTIVITY';
export const SET_TESTS = 'SET_TESTS';
export const SET_EDIT_VALUT = 'SET_EDIT_VALUT';
export const SET_SAVED = 'SET_SAVED';
export const SET_ADD_ASSETS = 'SET_ADD_ASSETS';
export const SET_LEGACY_REPORT = 'SET_LEGACY_REPORT';
export const SET_INITAIL_VAULT = 'SET_INITAIL_VAULT';
export const SET_ASSET_INFO = 'SET_ASSET_INFO';
export const SET_VAULTS = 'SET_VAULTS';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const SET_SCROLL_POS = 'SET_SCROLL_POS';
export const SET_TOTAL_RECORDS = 'SET_TOTAL_RECORDS';

// export const SET_ASSET_INFO = "SET_ASSET_INFO"
// export const SET_TEST_ASSETES = "SET_TEST_ASSETES"
export const actions = {
  setIsEdit: (data: any) => {
    return {
      type: SET_EDIT_VALUT,
      payload: data,
    };
  },
  setIsSaved: (data: any) => {
    return {
      type: SET_SAVED,
      payload: data,
    };
  },
  setShowActivity: (data: any) => {
    return {
      type: SET_SHOW_ACTIVITY,
      payload: data,
    };
  },
  setShowTest: (data: any) => {
    return {
      type: SET_TESTS,
      payload: data,
    };
  },
  setAddAsit: (data: any) => {
    return {
      type: SET_ADD_ASSETS,
      payload: data,
    };
  },
  setlegacyReport: (data: any) => {
    return {
      type: SET_LEGACY_REPORT,
      payload: data,
    };
  },
  setValutData: (data: any) => {
    return {
      type: SET_INITAIL_VAULT,
      payload: data,
    };
  },
  setAssetInfo: (data: any) => {
    return {
      type: SET_ASSET_INFO,
      payload: data
    };
  },
  setVaults: (data: any) => {
    return {
      type: SET_VAULTS,
      payload: data
    };
  },
  setPageSize: (data: any) => {
    return {
      type: SET_PAGE_SIZE,
      payload: data
    };
  },
  setScrollPos: (data: any) => {
    return {
      type: SET_SCROLL_POS,
      payload: data
    };
  },
  setTotalRecords: (data: any) => {
    return {
      type: SET_TOTAL_RECORDS,
      payload: data
    };
  }
};

const initialState = {
  isedit: false,
  istest: false,
  isactivity: false,
  issaved: false,
  isaddAsset: false,
  islegacy: false,
  ValutInfo: {},
  AssetInfo: {},
  vaults: [],
  rPageSize: 10,
  rPrevScrollPos: 0,
  rTotalRecords: 0
};

export const reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case SET_EDIT_VALUT:
      return { ...state, isedit: action.payload };
    case SET_SHOW_ACTIVITY:
      return { ...state, isactivity: action.payload };
    case SET_TESTS:
      return { ...state, istest: action.payload };
    case SET_SAVED:
      return { ...state, issaved: action.payload };
    case SET_ADD_ASSETS:
      return { ...state, isaddAsset: action.payload };
    case SET_LEGACY_REPORT:
      return { ...state, islegacy: action.payload };
    case SET_INITAIL_VAULT:
      return { ...state, valutInfo: action.payload };
    case SET_ASSET_INFO:
      return { ...state, AssetInfo: action.payload };
    case SET_VAULTS:
      return { ...state, vaults: action.payload };
    case SET_PAGE_SIZE:
      return { ...state, rPageSize: action.payload };
    case SET_SCROLL_POS:
      return { ...state, rPrevScrollPos: action.payload };
    case SET_TOTAL_RECORDS:
      return { ...state, rTotalRecords: action.payload };

    default:
      return state;
  }
};
