/* eslint-disable jsx-a11y/anchor-is-valid */
import Filter from 'app/components/filters';
import RoleBaseAccess from 'app/components/shared/roleBaseAccess.component';
import { error, success } from 'app/components/toast';
import { rescheduleJob } from 'app/modules/auth/redux/jobs/JobsCRUD';
import {
  SET_BUILDING_MODAL_VISIBLE,
  SET_FM_MODAL_VISIBLE,
  actions
} from 'app/modules/auth/redux/jobs/actions';
import { SET_ADMIN_OPERATIVE } from 'app/modules/auth/redux/settings';
import {
  SET_ADD_ASSETS,
  SET_EDIT_VALUT,
  SET_LEGACY_REPORT,
  SET_SAVED,
  SET_SHOW_ACTIVITY,
  SET_TESTS,
} from 'app/modules/auth/redux/vault';
import clsx from 'clsx';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useCurrentRoute } from '../../../../constants/Utils';
import { ROUTES } from '../../../../constants/routes';
import { useLayout } from '../../core';
import { DefaultTitle } from '../header/page-title/DefaultTitle';

const Toolbar1: FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [rescheduleJobVisible, setRescheduleJobVisible] = React.useState(false);
  const { classes } = useLayout();
  const { pathname } = useLocation();
  let route = useCurrentRoute();

  function extractIdFromUrl(url: string): string | null {
    const parts = url.split('/');
    return parts[parts.length - 1] || null;
  }

  const user = localStorage.getItem('user');

  const userData = user ? JSON.parse(user)?.data : null;


  const id: string | null = extractIdFromUrl(pathname);

  const [cancelVisible, setCancelVisible] = React.useState<boolean>(false);
  const getNextRoute = (): string => {
    if (route === ROUTES.DASHBOARD) {
      return ROUTES.DASHBOARD;
    } else if (route === ROUTES.ADMINISTRATION_JOBS) {
      return ROUTES.ADMINISTRATION_JOBS_CREATE;
    } else if (route === `${ROUTES.ADMINISTRATION_JOBS_EDIT}/${id}`) {
      return ROUTES.ADMINISTRATION_JOBS_EDIT;
    } else if (route === `${ROUTES.VAULT_DETAIL}${id}`) {
      return ROUTES.VAULT_DETAIL;
    } else if (route === `${ROUTES.ASSETS_DETAIL}${id}`) {
      return ROUTES.ASSETS_DETAIL;
    } else if (route === ROUTES.REPORT_LISTING) {
      return ROUTES.REPORT_LISTING;
    } else if (pathname === ROUTES.NEWS_LIST) {
      return ROUTES.NEWS_LIST;
    } else if (route === ROUTES.ADMINISTRATION_JOBS_CREATE) {
      return ROUTES.ADMINISTRATION_JOBS;
    } else if (route === ROUTES.CLIENT_LIST || route === ROUTES.ARCHIVE_CLIENT) {
      return ROUTES.CLIENT_LIST;
    } else if (pathname === ROUTES.BUILDING_LIST || pathname === ROUTES.BUILDING_ARCHIVE) {
      return ROUTES.BUILDING_LIST;
    } else if (pathname === ROUTES.ADMIN_OPERATIVE) {
      return ROUTES.ADMIN_OPERATIVE;
    } else if (pathname === ROUTES.ACCESIBILTY) {
      return ROUTES.ACCESIBILTY;
    } else if (pathname === ROUTES.FIREBARRIROR) {
      return ROUTES.FIREBARRIROR;
    } else if (pathname === ROUTES.INSTALLATION) {
      return ROUTES.INSTALLATION;
    } else if (pathname === ROUTES.DAMPTER) {
      return ROUTES.DAMPTER;
    } else if (pathname === ROUTES.DUCT) {
      return ROUTES.DUCT;
    } else if (pathname === ROUTES.SHAPS) {
      return ROUTES.SHAPS;
    } else if (pathname === ROUTES.DEFECT_SETTINGS) {
      return ROUTES.DEFECT_SETTINGS;
    } else if (pathname === ROUTES.FACILITY_MANAGER) {
      return ROUTES.FACILITY_MANAGER;
    } else if (pathname === ROUTES.FACILITY_MANAGER_ARCHIVE) {
      return ROUTES.FACILITY_MANAGER;
    } else if (pathname === ROUTES.OPERATIVE_LIST || pathname === ROUTES.OPERATIVE_ARCHIVE) {
      return ROUTES.OPERATIVE_LIST;
    }
    else if (pathname === ROUTES.ASSETS_ARCHIVE) {
      return ROUTES.ASSETS_ARCHIVE;
    }

    return '';
  };

  const [nextRoute, setNextRoute] = React.useState<string>(getNextRoute());
  const [btnTitle, setBtnTitle] = React.useState<string>('');
  const [sidebarVisible, setSidebarVisible] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const { isedit } = useSelector((state: any) => state.vault);
  const history = useHistory();

  React.useEffect(() => {
    setNextRoute(getNextRoute());
    if (pathname === ROUTES.ADMINISTRATION_JOBS_CREATE) {
      setCancelVisible(true);
    } else if (pathname === ROUTES.DASHBOARD) {
      setBtnTitle('Dashboard');
      setCancelVisible(false);
    } else if (pathname === ROUTES.FORUM_LIST) {
      // setBtnTitle('Create Question')
      setCancelVisible(false);
    } else if (pathname === ROUTES.CLIENT_LIST) {
      setBtnTitle('Add Profile');
      setCancelVisible(false);
      actions.setClientModalVisible(true);
    } else if (route === ROUTES.ARCHIVE_CLIENT) {
      setBtnTitle('Archive Profile');
      setCancelVisible(true);
    } else if (route === ROUTES.FACILITY_MANAGER) {
      setBtnTitle('Add FM');
      setCancelVisible(false);
    } else if (route === ROUTES.FACILITY_MANAGER_ARCHIVE) {
      setBtnTitle('Back');
      setCancelVisible(true);
    } else if (route === ROUTES.BUILDING_ARCHIVE) {
      setBtnTitle('Back');
      setCancelVisible(true);
    } else if (route === ROUTES.BUILDING_LIST) {
      setBtnTitle('Add Building');
      setCancelVisible(false);
    } else if (route === ROUTES.OPERATIVE_LIST) {
      setBtnTitle('Add Operative');
      setCancelVisible(false);
    } else if (route === ROUTES.OPERATIVE_ARCHIVE) {
      setBtnTitle('Back');
      setCancelVisible(true);
    } else if (route === ROUTES.ADMINISTRATION_JOBS_EDIT) {
      setBtnTitle('Re-Schedule Job');
      setCancelVisible(true);
    } else {
      setCancelVisible(false);
      setBtnTitle(`Create Job`);
    }
  }, [pathname, getNextRoute]);

  const onCreateClient = () => {
    history.push(ROUTES.CLIENT_ADD);
  };

  const onCreateFM = () => {
    dispatch({
      type: SET_FM_MODAL_VISIBLE,
      payload: true,
    });
  };

  const onCreateBuilding = () => {
    dispatch({
      type: SET_BUILDING_MODAL_VISIBLE,
      payload: true,
    });
  };

  const onCreateOperative = () => {
    history.push(ROUTES.OPERATIVE_ADD);
  };

  const showVaultActivity = () => {
    dispatch({
      type: SET_SHOW_ACTIVITY,
      payload: true,
    });
  };

  const showTest = () => {
    dispatch({
      type: SET_TESTS,
      payload: true,
    });
  };

  const showIsEdit = () => {
    if (isedit === true) {
      dispatch({
        type: SET_EDIT_VALUT,
        payload: false,
      });
      dispatch({
        type: SET_SAVED,
        payload: true,
      });
    } else {
      dispatch({
        type: SET_EDIT_VALUT,
        payload: true,
      });
    }
  };

  const handleAddAssets = () => {
    dispatch({
      type: SET_ADD_ASSETS,
      payload: true,
    });
  };

  const handleLegacy = () => {
    dispatch({
      type: SET_LEGACY_REPORT,
      payload: true,
    });
  };

  const handleAdmin = () => {
    dispatch({
      type: SET_ADMIN_OPERATIVE,
      payload: true,
    });
  };

  const goBack = () => {
    history.push('/administration/jobs');
  };

  const selectedJob = useSelector((state: any) => {
    return state.job.jobDetails;
  });

  const onRescheduleJob = async (worksheet: boolean = false) => {
    try {
      setLoading(true);
      const res = await rescheduleJob({ "inspectionJobId": id, "worksheet": worksheet });

      if (res?.status === 'Success') {
        setLoading(false);
        success("Job scheduled successfully", {
          onClose: goBack,
        });
        setRescheduleJobVisible(false);
      }
      else {
        setLoading(false);
        error(res?.message ?? "Something went wrong, please try again later");
      }
    }
    catch (err: any) {
      setLoading(false);
      error("Something went wrong, please try again later");
    }
  };

  return (
    <div className='toolbar' id='kt_toolbar'>
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-lg-flex flex-stack py-3 py-lg-0')}
      >
        <DefaultTitle />

        <Dialog header="Re-schedule Job" onHide={() => setRescheduleJobVisible(false)} visible={rescheduleJobVisible}>
          <p className='fs-5 fw-bold'>Do you want to keep the existing worksheet of the job?</p>
          <div style={{ textAlign: 'end', padding: '20px' }}>
            <Button
              onClick={() => onRescheduleJob(false)}
              type='button' className='btn btn-primary-bordered'
              label={loading ? 'Please wait...' : 'No'}
              icon={loading && 'pi pi-spin pi-spinner'}
              disabled={loading}
            />
            <RoleBaseAccess moduleName="Job" isSubmit>
              <Button onClick={() => {
                onRescheduleJob(true);
              }}
                disabled={loading}
                type='button'
                className='btn btn-primary ms-4 px-10'
                label={loading ? 'Please wait...' : 'Yes'}
                icon={loading && 'pi pi-spin pi-spinner'}
              />
            </RoleBaseAccess>
          </div>
        </Dialog>

        <div className='d-lg-flex align-items-center py-1'>
          {
            !cancelVisible ? (
              nextRoute === ROUTES.FACILITY_MANAGER ? (
                <div
                  className="d-flex flex-row gap-2"
                >
                  <RoleBaseAccess moduleName="FM" isSubmit>
                    <button className='btn btn-primary' onClick={onCreateFM} id='create-fm'>
                      {btnTitle}
                    </button>
                  </RoleBaseAccess>
                  {userData?.operativeTypeEnum === 3 || userData?.operativeTypeEnum === 4 ?
                    <></> : <Link
                      to={'/fm/archive'}
                      style={{ backgroundColor: '#DDDDDD', color: '#000000' }}
                      className={`btn btn d-block`}
                    >
                      Archive
                    </Link>}
                </div>
              ) :
                nextRoute === ROUTES.CLIENT_LIST || nextRoute === ROUTES.ARCHIVE_CLIENT ? (
                  <div
                    className={`d-flex flex-row gap-2`}
                  >
                    <RoleBaseAccess moduleName="Profiles" isSubmit>
                      <button className='btn btn-primary ' onClick={onCreateClient} id='create-client'>
                        {btnTitle}
                      </button>
                    </RoleBaseAccess>
                    {userData?.operativeTypeEnum === 3 || userData?.operativeTypeEnum === 4 ?
                      <></> : <Link
                        to={'/profile/archive'}
                        style={{ backgroundColor: '#DDDDDD', color: '#000000' }}
                        className={`btn btn d-block`}
                      >
                        Archive
                      </Link>}
                  </div>
                ) : nextRoute === ROUTES.OPERATIVE_ARCHIVE || nextRoute === ROUTES.OPERATIVE_LIST ? (
                  <div
                    className={`d-flex flex-row gap-2`}
                  >
                    <RoleBaseAccess moduleName="Operatives" isSubmit>
                      <button
                        className='btn btn-primary'
                        onClick={onCreateOperative}
                        id='create-operative'
                      >
                        {btnTitle}
                      </button>
                    </RoleBaseAccess>
                    {userData?.operativeTypeEnum === 3 || userData?.operativeTypeEnum === 4 ?
                      <></> : <Link
                        to={'/operative/archive'}
                        style={{ backgroundColor: '#DDDDDD', color: '#000000' }}
                        className={`btn btn d-block`}
                      >
                        Archive
                      </Link>}
                  </div>
                ) : nextRoute === ROUTES.DEFECT_SETTINGS || nextRoute === ROUTES.DEFECT_SETTINGS ? (
                  <></>
                ) : nextRoute === ROUTES.ADMIN_OPERATIVE ? (
                  <div className=''>
                    <RoleBaseAccess moduleName='Settings' isSubmit>
                      <button className='btn btn-primary ' onClick={handleAdmin} id='create-admin'>
                        Add Admin
                      </button>
                    </RoleBaseAccess>
                  </div>
                ) : nextRoute === ROUTES.ACCESIBILTY || nextRoute === ROUTES.ACCESIBILTY ? (
                  <></>
                ) : nextRoute === ROUTES.FIREBARRIROR || nextRoute === ROUTES.FIREBARRIROR ? (
                  <></>
                ) : nextRoute === ROUTES.INSTALLATION || nextRoute === ROUTES.INSTALLATION ? (
                  <></>
                ) : nextRoute === ROUTES.DAMPTER || nextRoute === ROUTES.DAMPTER ? (
                  <></>
                ) : nextRoute === ROUTES.DUCT || nextRoute === ROUTES.DUCT ? (
                  <></>
                ) : nextRoute === ROUTES.BUILDING_ARCHIVE || nextRoute === ROUTES.BUILDING_LIST ? (
                  <div
                    className={`d-flex flex-row gap-2`}
                  >
                    <RoleBaseAccess moduleName="Buildings Register" isSubmit>
                      <button
                        className='btn btn-primary'
                        onClick={onCreateBuilding}
                        id='create-building'
                      >
                        {btnTitle}
                      </button>
                    </RoleBaseAccess>
                    {userData?.operativeTypeEnum === 3 || userData?.operativeTypeEnum === 4 ?
                      <></> : <Link
                        to={'/buildings/archive'}
                        style={{ backgroundColor: '#DDDDDD', color: '#000000' }}
                        className={`btn btn d-block`}
                      >
                        Archive
                      </Link>}
                  </div>
                ) : nextRoute === ROUTES.VAULT_DETAIL ? (
                  <div className='d-lg-flex flex-row gap-2 '>
                    <>
                      <button type='button' className={`btn btn-sm btn-light w-100 w-lg-auto mb-5 mb-lg-0`} onClick={() => history.push('/vault/home')}>
                        <i className='pi pi-arrow-left'></i> Back
                      </button>
                      <RoleBaseAccess moduleName="Vault" isSubmit>
                        <button className='btn btn-primary w-100 w-lg-auto mb-5 mb-lg-0' onClick={() => handleAddAssets()}>
                          Add Assets
                        </button>
                      </RoleBaseAccess>
                      {userData?.operativeTypeEnum === 3 || userData?.operativeTypeEnum === 4 ?
                        <></> : <Link to='/vault/archivedassets'>
                          <button
                            style={{ backgroundColor: '#DDDDDD', color: '#000000' }}
                            className='btn btn text-dark w-100 w-lg-auto mb-5 mb-lg-0'
                          >
                            Archive
                          </button>
                        </Link>}
                    </>
                  </div>
                ) :
                  nextRoute === ROUTES.ASSETS_ARCHIVE ? (
                    <button type='button' className={`btn btn-sm btn-light`} onClick={() => history.goBack()}>
                      <i className='pi pi-arrow-left'></i> Back
                    </button>
                  ) :
                    nextRoute === ROUTES.ASSETS_DETAIL ? (
                      <div className='d-lg-flex justify-content-evenly'>
                        <button type='button' className={`btn btn-sm btn-light`} onClick={() => history.goBack()}>
                          <i className='pi pi-arrow-left'></i> Back
                        </button>
                        &nbsp; &nbsp;
                        <button onClick={() => showVaultActivity()} className='btn btn-primary'>
                          Defect Log
                        </button>
                        &nbsp; &nbsp;
                        <button onClick={() => showTest()} className='btn btn-primary'>
                          Test History
                        </button>
                        &nbsp; &nbsp;

                        <RoleBaseAccess moduleName="Vault" isSubmit>
                          <button className='btn btn-primary' onClick={() => showIsEdit()}>
                            {isedit ? 'Save' : 'Edit'}
                          </button>
                        </RoleBaseAccess>
                      </div>
                    ) : nextRoute === ROUTES.NEWS_LIST ? (
                      <div>
                        {userData?.operativeTypeEnum === 3 || userData?.operativeTypeEnum === 4 ?
                          <></> : <Link to='/news/archive/'>
                            <button
                              style={{ backgroundColor: '#DDDDDD', color: '#000000' }}
                              className='btn btn'
                            >
                              Archive
                            </button>
                          </Link>}
                      </div>
                    ) : nextRoute === ROUTES.REPORT_LISTING ? (
                      <div
                        className={`d-flex justify-content-evenly`}
                      >
                        <RoleBaseAccess moduleName="Report Library" isSubmit>
                          <button onClick={() => handleLegacy()} className='btn btn-primary'>
                            Upload Legacy Report
                          </button>
                          &nbsp; &nbsp;
                        </RoleBaseAccess>
                        {userData?.operativeTypeEnum === 3 || userData?.operativeTypeEnum === 4 ?
                          <></> : <Link
                            to={'/reports/archive-report'}
                            style={{ backgroundColor: '#DDDDDD', color: '#000000' }}
                            className={`btn btn d-block`}
                          >
                            Archive
                          </Link>}
                      </div>
                    ) : nextRoute === ROUTES.DASHBOARD ? (
                      <>
                        {/* <input className='form-control' placeholder='Search' onChange={e => setSearchText(e.target.value)} /> */}
                        <i
                          className='la la-search text-2xl cursor-pointer'
                          onClick={() => setSidebarVisible(true)}
                        />
                        <Filter setSidebarVisible={setSidebarVisible} sidebarVisible={sidebarVisible} />
                      </>
                    ) : nextRoute === ROUTES.ADMINISTRATION_JOBS_CREATE ? (
                      <div>
                        <RoleBaseAccess moduleName="Job" isSubmit>
                          <Link to={nextRoute} className={`btn btn-sm btn-primary d-block`}>
                            {btnTitle}
                          </Link>
                        </RoleBaseAccess>
                      </div>
                    ) : nextRoute === ROUTES.ADMINISTRATION_JOBS_EDIT ? (
                      <div>
                        <RoleBaseAccess moduleName="Job" isSubmit>
                          {userData?.operativeTypeEnum === 1 && selectedJob?.jobSignedOff &&
                            <button
                              className='btn btn-primary btn-sm'
                              type='button'
                              onClick={() => setRescheduleJobVisible(true)}
                              data-toggle='modal'
                              data-target='#publishReport'
                            >
                              Re-Schedule Job
                            </button>
                          }
                        </RoleBaseAccess>
                      </div>
                    )
                      : <></>
            ) : (
              <>
                {nextRoute === ROUTES.CLIENT_LIST ? (
                  <Link to={nextRoute} className={`btn btn-sm btn-secondary`}>
                    Back
                  </Link>
                ) : (
                  <Link to={nextRoute} className={`btn btn-sm btn-light`}>
                    Cancel
                  </Link>
                )}
              </>
            )
          }
        </div>
      </div>
    </div>
  );
};

export { Toolbar1 };

