export const SET_CLIENT_ID = 'SET_CLIENT_ID';
export const SET_CLIENT_DATA = 'SET_CLIENT_DATA';
export const SET_CLIENTS = 'SET_CLIENTS';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const SET_SCROLL_POS = 'SET_SCROLL_POS';
export const SET_TOTAL_RECORDS = 'SET_TOTAL_RECORDS';

export const actions = {
  setClientId: (data: any) => {
    return {
      type: SET_CLIENT_ID,
      payload: data,
    };
  },
  setClientData: (data: any) => {
    return {
      type: SET_CLIENT_DATA,
      payload: data,
    };
  },
  setClients: (data: any) => {
    return {
      type: SET_CLIENTS,
      payload: data
    };
  },
  setPageSize: (data: any) => {
    return {
      type: SET_PAGE_SIZE,
      payload: data
    };
  },
  setScrollPos: (data: any) => {
    return {
      type: SET_SCROLL_POS,
      payload: data
    };
  },
  setTotalRecords: (data: any) => {
    return {
      type: SET_TOTAL_RECORDS,
      payload: data
    };
  }
};

const initialState = {
  clientId: null,
  clientData: {},
  clients: [],
  rPageSize: 10,
  rPrevScrollPos: 0,
  rTotalRecords: 0
};

export const reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case SET_CLIENT_ID:
      return { ...state, clientId: action.payload };
    case SET_CLIENT_DATA:
      return { ...state, clientData: action.payload };
    case SET_CLIENTS:
      return { ...state, clients: action.payload };
    case SET_PAGE_SIZE:
      return { ...state, rPageSize: action.payload };
    case SET_SCROLL_POS:
      return { ...state, rPrevScrollPos: action.payload };
    case SET_TOTAL_RECORDS:
      return { ...state, rTotalRecords: action.payload };
    default:
      return state;
  }
};
