import { BuildingDetailedListProps } from 'app/modules/auth/redux/Types';
import {
  getBuildingsWithDetails,
  getClients,
  getFacilityManagers,
} from 'app/modules/auth/redux/jobs/JobsCRUD';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { buildingDetailsArgs } from '../buildings/Buildings';
import { fmArgs } from '../facility-manager/FacilityManager';
import { AssetsTypesProps } from './modal';

import LoaderComman from 'app/components/shared/loader.component';
import { SET_PAGE_SIZE, SET_SCROLL_POS, SET_TOTAL_RECORDS, SET_VAULTS } from 'app/modules/auth/redux/vault';
import { COMMON } from 'constants/commonConstant';
import { ROUTES } from 'constants/routes';
import debounce from 'lodash.debounce';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import logoImg from '../../../assets/associate.png';

export const vaultArgs = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  limit: 10,
  query: {
    critarion: {
      active: true,
    },
    fields: '',
  },
};

export const AssetTypes = {
  installation: 'installation',
  ductDamper: 'damper',
  accessPanel: 'accessPanel',
  fireBarrier: 'fireBarrier',
  accessibility: 'accessibility',
};

export const vaultDetailedArgs = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  limit: 50,
  query: {
    critarion: {
      active: true,
    },
    buildingFields: '',
    addedby: ' email name',
    lastModifiedBy: ' email name',
  },
};

export interface CreateVaultProps {
  _id?: string;
  vaultName: string;
}

export interface VaultListingProps {
  _id: string;
  vaultName: string;
  active: boolean;
}

const Vault = () => {
  const history = useHistory();

  const { vaults, rPageSize, rPrevScrollPos, rTotalRecords } = useSelector((state: any) => state.vault);

  const scrollDivRef = useRef<any>(null);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [buildingsList, setBuildingsList] = useState<BuildingDetailedListProps[]>([]);
  const [buildings, setBuildings] = useState<BuildingDetailedListProps[]>([]);
  const [sortProperty, setSortProperty] = useState({
    active: true,
  });
  const [clients, setClients] = useState<any>([]);
  const [Fm, setFm] = useState<any>([]);
  const [sortOrder, setSortOrder] = useState(-1);

  const [totalRecords, setTotalRecords] = useState(rTotalRecords);
  const [firstRow, setFirstRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(rPageSize);
  const [isTableLoading, setTableLoading] = useState(false);
  const [filterValue, setFilterValue] = useState('');

  const [sortField, setSortField] = useState('createdAt');
  const [isChanged, setChanged] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(rPrevScrollPos);

  const getDefects = (buildingAssets: any): number => {
    let numberOfDefects = 0;

    if (!buildingAssets.assetArchived) {
      COMMON.defectTypes.forEach((x) => {
        numberOfDefects += buildingAssets[x]?.defects?.length
          ? buildingAssets[x]?.defects.filter((x: any) => !x.defectRectification.defectRectified)
            .length
          : 0;
      });
    }

    if (buildingAssets?.tests?.length) {
      buildingAssets?.tests?.map((x: any) => {
        numberOfDefects += x?.defects?.length
          ? x?.defects.filter((x: any) => !x.defectRectification.defectRectified)
            .length
          : 0;
      });
    }

    return numberOfDefects;
  };

  const getDetailedBuildingListing = async (isStore = true) => {
    buildingDetailsArgs.pageNum = pageNumber;
    buildingDetailsArgs.limit = pageSize;
    buildingDetailsArgs.sortorder = sortOrder;
    buildingDetailsArgs.sortproperty = sortField;
    buildingDetailsArgs.search = filterValue;

    const args = buildingDetailsArgs;
    args.sortorder = sortOrder;
    args.offset = 0;
    args.limit = pageSize;
    args.query.critarion.active = sortProperty.active;
    const response = await getBuildingsWithDetails(args);

    if (isStore) {
      dispatch({
        type: SET_TOTAL_RECORDS,
        payload: response.data.totalRecords ? response.data.totalRecords : 0,
      });
    }

    setTotalRecords(response.data.totalRecords);

    let data = response?.data?.buildings?.map((building: any) => {
      let totalDefects = 0;
      let totalAssets = 0;
      let totalAssetsWithDefects = 0;
      totalAssets = building.buildingAssets.length;
      building.buildingAssets.map((asset: any) => {
        let buildingAssets: AssetsTypesProps = asset;
        totalDefects += getDefects(buildingAssets);
      });
      building.totalAssets = totalAssets;
      building.totalAssetsWithDefects = totalAssetsWithDefects;

      let month = building?.testRegime?.includes('3')
        ? 3
        : building?.testRegime?.includes('6')
          ? 6
          : building?.testRegime?.includes('9')
            ? 9
            : building?.testRegime?.includes('12')
              ? 12
              : 0;

      let tDate;
      if (building?.testDate) {
        tDate = new Date(building?.testDate);
        tDate.setMonth(tDate.getMonth() + month);
      }

      return {
        ...building,
        clientName: building.client?.clientName,
        clientRepresentativeName: building.clientRepresentative?.clientRepresentativeName,
        facilityManagerName: building.facilityManager?.facilityManagerName,
        testDate: building.testDate ? moment(building.testDate).format('DD/MM/YYYY') : '',
        nextTestDate: tDate ? moment(tDate).format('DD/MM/YYYY') : '',
        numberOfAssets: building.buildingAssets.filter((y: any) => y.assetArchived === false).length,
        numberOfDefects: totalDefects,
      };
    });
    setBuildings([]);
    setBuildings(data);
    setLoading(false);
    setTableLoading(false);
    return data;
  };

  const clientsArgs = {
    sortproperty: 'createdAt',
    sortorder: -1,
    offset: 0,
    limit: 50,
    search: '',
    pageNum: 1,
    query: {
      critarion: {
        active: true,
      },
      fields: 'clientName',
    },
  };

  const fetchClients = async () => {
    const clientsData = await getClients(clientsArgs);
    return clientsData.data.clients;
  };

  const getFacilityManagersListing = async () => {
    const facilityManagersData = await getFacilityManagers(fmArgs);
    return facilityManagersData.data.facilityManagers;
  };

  const dispatch = useDispatch();

  const getBuildings = async (isStore = true) => {
    const data = await getDetailedBuildingListing(isStore);
    setBuildingsList(data);
    setBuildings(data);

    if (isStore) {
      dispatch({
        type: SET_VAULTS,
        payload: data,
      });
    }
  };

  const getAllData = async () => {
    if (Array.isArray(vaults) && vaults.length) {
      setBuildingsList(vaults);
      setBuildings(vaults);
    }
    else {
      await getBuildings();
    }

    let [facilityManagers, clients] = await Promise.all([
      getFacilityManagersListing(),
      fetchClients(),
    ]);


    setLoading(false);
    setClients(clients);
    setFm(facilityManagers);

    if (scrollDivRef.current) {
      scrollDivRef.current.scrollTo(0, prevScrollPos); // Adjust the x and y values as needed
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    if (isChanged) {
      const timeoutId = setTimeout(() => {
        setTableLoading(true);
        getBuildings(false);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [filterValue]);

  useEffect(() => {
    const handleScroll = debounce((e: any) => {
      const scrollContainer = e.target;
      const currentScrollPos = e.target.scrollTop;
      const maxScrollPos = scrollContainer.scrollHeight - scrollContainer.clientHeight;

      if ((currentScrollPos > rPrevScrollPos) && (currentScrollPos >= (maxScrollPos - 1))) {
        setChanged(true);
        setPrevScrollPos(currentScrollPos);
      }
      dispatch({
        type: SET_SCROLL_POS,
        payload: currentScrollPos,
      });
    }, 100);

    const scrollContainer = document.querySelector('.card-height');
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [prevScrollPos]);

  useEffect(() => {
    if (prevScrollPos && isChanged) {
      setPageSize(pageSize + 10);
    }
  }, [prevScrollPos]);

  useEffect(() => {
    if (pageSize && isChanged) {
      dispatch({
        type: SET_PAGE_SIZE,
        payload: pageSize,
      });

      setLoading(true);
      getBuildings();
    }
  }, [pageSize]);

  const onEditBuilding = (id: string) => {
    history.push(`${ROUTES.VAULT_DETAIL}${id}`);
  };

  const ProfilePic = styled.img`
  max-width: 200px;
  height: 150px;
  border-radius: 3px;
  border: 1px solid #ccc;
`;

  return (
    <LoaderComman isRender={!isLoading}>

      <div className='h-100'>
        <div className='row'>
          <div className='col-lg-12 mb-3'>
            <div className='card'>
              <div className='card-body py-4'>
                <div className='d-lg-flex justify-content-between'>
                  <div className='form-label text-muted d-flex align-items-end'>Total Buildings: &nbsp;<h2 className='mb-0'>{totalRecords}</h2></div>
                  <InputText
                    value={filterValue}
                    onChange={(e) => {
                      setChanged(true);
                      setFilterValue(e.target.value);
                    }}
                    placeholder='Search...'
                    className='form-control w-xl-25 w-lg-50' />
                </div>
              </div>
            </div>

          </div>
        </div>
        <div ref={scrollDivRef} className='card-height' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
          <div className='row'>
            {
              buildings?.length ?
                buildings.map((x: any) => {
                  return (
                    <div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-6 my-3'>
                      <div className="card h-100">

                        <Card title={`${x.buildingName}`} className='h-100'
                          onClick={() => onEditBuilding(x._id)}
                        >
                          <div className='d-xxl-flex justify-content-between'>

                            {x.buildingImage ? (
                              <div className='avatar-preview mb-5 mb-xl-0'>
                                <ProfilePic
                                  src={
                                    x.buildingImage && typeof x.buildingImage !== 'string'
                                      ? URL.createObjectURL(x.buildingImage)
                                      : process.env.REACT_APP_IMAGE_URL + x.buildingImage
                                  }
                                  alt='Logo image'
                                />
                              </div>
                            ) : (
                              <div className='avatar-preview mb-5 mb-xl-0'>
                                <ProfilePic src={logoImg} alt='' />
                              </div>
                            )}

                            <div className='pt-8 ps-4 card-border'>
                              <p className='form-label fw-bolder mb-5'>{x.buildingReference}</p>
                              <p className='form-label d-flex justify-content-between'>Assets: &nbsp;<div className='form-label'>{x.buildingAssets?.length}</div></p>
                              <p className='form-label d-flex justify-content-between'>Defects: &nbsp;<div className='form-label'>{x.numberOfDefects}</div></p>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                  );
                })
                :
                <div className='col-lg-12'>
                  <div className='card'>
                    <div className='card-body h5 text-muted'>
                      No records found.
                    </div>
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
    </LoaderComman>
  );
};

export default Vault;
