import React from 'react';
import {
  // ExploreMain,
  ActivityDrawer,
  DrawerMessenger,
  InviteUsers,
  Main,
  UpgradePlan,
} from '../partials';
import { AsideDefault } from './components/aside/AsideDefault';
import { Content } from './components/Content';
import { Footer } from './components/Footer';
import { HeaderWrapper } from './components/header/HeaderWrapper';
import { ScrollTop } from './components/ScrollTop';
import { Toolbar } from './components/toolbar/Toolbar';
import { PageDataProvider } from './core';
import { MasterInit } from './MasterInit';

const MasterLayout: React.FC = ({ children }) => {
  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />
          <div id='kt_content' className='mt-6 d-flex flex-column flex-column-fluid'>
            <Toolbar />
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <div className='container-fluid'>
                <Content>{children}</Content>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/* <ExploreMain /> */}
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <Main />
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}

      <MasterInit />
      <ScrollTop />
    </PageDataProvider>
  );
};

export { MasterLayout };

