import {combineReducers} from 'redux'
import {all} from 'redux-saga/effects'

import * as client from 'app/modules/auth/redux/client/actions'
import * as reportHeader from 'app/modules/auth/redux/create-report/reducer'
import * as job from 'app/modules/auth/redux/jobs/actions'
import * as operative from 'app/modules/auth/redux/operatives/actions'
import * as report from 'app/modules/auth/redux/report/actions'
import * as settings from 'app/modules/auth/redux/setting/actions'
import * as vault from 'app/modules/auth/redux/vault/actions'
import * as auth from '../../app/modules/auth'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  job: job.reducer,
  vault: vault.reducer,
  client: client.reducer,
  report: report.reducer,
  reportHeader: reportHeader.reducer,
  settings: settings.reducer,
  operative: operative.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
