/* eslint-disable array-callback-return */
import {
  createCertificateRemedialReport,
  createRemedialActionLogReport,
  createRemedialCompetencyReport,
  createRemedialInspectionReport,
  createRemedialReportFooter,
  createRemedialReportHeader,
  createRemedialSummaryReport,
  getBuilding,
  getOperative,
  getOperatives,
  publishReport,
} from 'app/modules/auth/redux/jobs/JobsCRUD';
import { buildingByIdArgs } from 'app/pages/buildings/Buildings';
import { IOperativeCompetence } from 'app/pages/reports/interfaces';
import moment from 'moment';

export const operativesArgs = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  limit: 50,
  query: {
    critarion: {
      active: true,
      role: '',
    },
    fields: 'operativeRefNo operativeName certificates operativeCompetencies user active',
  },
};

export const getOperativesByIds = async (operatives: any) => {
  const args: any = operativesArgs;
  args.query.fields = 'operativeRefNo operativeName operativeCompetencies';
  const operativesData: any[] = [];
  await Promise.all(
    operatives.map(async (op: string) => {
      args.query.critarion._id = op;
      delete args.query.critarion.role;
      const response = await getOperatives(args);

      const operative = response.data.operatives[0];
      let obj: IOperativeCompetence = {
        operativeHeader: operative.operativeRefNo || '',
        operativeText: operative.operativeName || '',
        operativeImage: operative.operativeCompetencies.certificatesUrls.length
          ? operative.operativeCompetencies.certificatesUrls[0]
          : '',
      };
      operativesData.push(obj);
    })
  );
  return operativesData;
};

export const getOperativeDetails = async (id: string) => {
  const args: any = operativesArgs;
  const critarion = { _id: id };
  args.critarion = critarion;
  const response = await getOperative(args);
  return response;
};

export const onSaveReportToLibrary = async (data: any) => {
  const res = await publishReport(data);
  if (res.status === 'Success') {
    return `success`;
  } else {
    return 'error';
  }
};

export const saveToStorage = async (key: string, data: any) => {
  await localStorage.setItem(key, JSON.stringify(data));
};

export const getFromStorage = async (key: string) => {
  const data = await localStorage.getItem(key);
  if (data) {
    return await JSON.parse(data);
  }
  return null;
};

export const removeEveryKey = () => {
  localStorage.removeItem('summary');
  localStorage.removeItem('assetPage');
  localStorage.removeItem('inventory');
  localStorage.removeItem('defectLog');
  localStorage.removeItem('assetRegister');
  localStorage.removeItem('certificate');
  localStorage.removeItem('guidance');
  localStorage.removeItem('competency');
  localStorage.removeItem('services');
  localStorage.removeItem('header');
  localStorage.removeItem('footer');
};

export const getNextTestDate = (testDate: any, testRegime: string) => {
  const durations = testRegime.split(' ');
  const nextDate = moment(testDate)
    .add(moment.duration(Number(durations[0]), 'month'))
    .format();
  return nextDate;
};

export const getRemedialReportSummary = async (job: any) => {
  const summary: any = {};
  const assetsRemedied: any[] = [];

  job.buildingAssets.map(async (asset: any, i: number) => {
    let accessibility = asset?.buildingAsset?.accessibility;
    let installation = asset.buildingAsset.installation;
    let fireBarrier = asset.buildingAsset.fireBarrier;
    let accessPanel = asset.buildingAsset.accessPanel;
    let assetRef = asset.buildingAsset.assetReference;

    if (accessibility.defect || accessibility.defect !== null) {
      await accessibility.defects.map((defect: any) => {
        assetsRemedied.push({
          assetRef: assetRef || 'N/A',
          defectCategory: defect.defectCategoryName || 'N/A',
          defectNotes: defect.defectDetails || 'N/A',
          defectAction: defect.defectRectification.defectRectified
            ? 'DEFECT RECTIFIED'
            : 'DEFECT UNRESOLVED',
        });
      });
    }

    if (installation.defect || installation.defect !== null) {
      await installation.defects.map((defect: any) => {
        assetsRemedied.push({
          assetRef: assetRef || 'N/A',
          defectCategory: defect.defectCategoryName || 'N/A',
          defectNotes: defect.defectDetails || 'N/A',
          defectLogImageUrls: defect.defectImageUrls || [],
        });
      });
    }
    if (fireBarrier.defect || fireBarrier.defect !== null) {
      await fireBarrier.defects.map((defect: any) => {
        assetsRemedied.push({
          assetRef: assetRef || 'N/A',
          defectCategory: defect.defectCategoryName || 'N/A',
          defectNotes: defect.defectDetails || 'N/A',
          defectLogImageUrls: defect.defectImageUrls || [],
        });
      });
    }
    if (accessPanel.defect || accessPanel.defect !== null) {
      await accessPanel.defects.map((defect: any) => {
        assetsRemedied.push({
          assetRef: assetRef || 'N/A',
          defectCategory: defect.defectCategoryName || 'N/A',
          defectNotes: defect.defectDetails || 'N/A',
          defectLogImageUrls: defect.defectImageUrls || [],
        });
      });
    }
  });

  summary.assetsRemedied = assetsRemedied;
  summary.summaryTitle = 'Summary';
  summary.summaryParas = [
    `BS 9999:2017 states: "Arrangements should be made for all fire dampers to be tested by a competent person on completion of the installation and at least annually, and to be repaired or replaced immediately if found to be faulty"`,
  ];

  return summary;
};

export const getRemedialReportActionLog = async (job: any) => {
  const data: any[] = [];

  await job.buildingAssets.map(async (asset: any, i: number) => {
    let accessibility = asset?.buildingAsset?.accessibility;
    let installation = asset.buildingAsset.installation;
    let fireBarrier = asset.buildingAsset.fireBarrier;
    let accessPanel = asset.buildingAsset.accessPanel;
    if (accessibility.defect || accessibility.defect !== null) {
      accessibility.defects.map((defect: any) => {
        data.push({
          name: 'Accessibility',
          assetRef: defect.assetReference || 'N/A',
          assetLocation: asset.buildingAsset.assetLocation || 'N/A',
          defectMainCategory: defect.defectCategoryName || 'N/A',
          defectSubCategory: defect.defectSubCategoryName || 'N/A',
          defectNotes: 'N/A',
          defectLogImageUrls: defect.defectImageUrls || [],
          remedialNotes: defect.defectDetails || 'N/A',
          remedialAction: defect.defectRectification.defectRectified
            ? 'DEFECT RECTIFIED'
            : 'DEFECT UNRESOLVED',
        });
      });
    }
    if (installation.defect || installation.defect !== null) {
      installation.defects.map((defect: any) => {
        data.push({
          name: 'Installation',
          assetRef: defect.assetReference || 'N/A',
          assetLocation: asset.buildingAsset.assetLocation || 'N/A',
          defectMainCategory: defect.defectCategoryName || 'N/A',
          defectSubCategory: defect.defectSubCategoryName || 'N/A',
          defectNotes: 'N/A',
          defectLogImageUrls: defect.defectImageUrls || [],
          remedialNotes: defect.defectDetails || 'N/A',
          remedialAction: defect.defectRectification.defectRectified
            ? 'DEFECT RECTIFIED'
            : 'DEFECT UNRESOLVED',
        });
      });
    }
    if (fireBarrier.defect || fireBarrier.defect !== null) {
      fireBarrier.defects.map((defect: any) => {
        data.push({
          name: 'Fire Barrier',
          assetRef: defect.assetReference || 'N/A',
          assetLocation: asset.buildingAsset.assetLocation || 'N/A',
          defectMainCategory: defect.defectCategoryName || 'N/A',
          defectSubCategory: defect.defectSubCategoryName || 'N/A',
          defectNotes: 'N/A',
          defectLogImageUrls: defect.defectImageUrls || [],
          remedialNotes: defect.defectDetails || 'N/A',
          remedialAction: defect.defectRectification.defectRectified
            ? 'DEFECT RECTIFIED'
            : 'DEFECT UNRESOLVED',
        });
      });
    }
    if (accessPanel.defect || accessPanel.defect !== null) {
      accessPanel.defects.map((defect: any) => {
        data.push({
          name: 'Access Panel',
          assetRef: defect.assetReference || 'N/A',
          assetLocation: asset.buildingAsset.assetLocation || 'N/A',
          defectMainCategory: defect.defectCategoryName || 'N/A',
          defectSubCategory: defect.defectSubCategoryName || 'N/A',
          defectNotes: 'N/A',
          defectLogImageUrls: defect.defectImageUrls || [],
          remedialNotes: defect.defectDetails || 'N/A',
          remedialAction: defect.defectRectification.defectRectified
            ? 'DEFECT RECTIFIED'
            : 'DEFECT UNRESOLVED',
        });
      });
    }
  });

  return data;
};

export const onPublishHeaderRemedial = async (data: any) => {
  const res = await createRemedialReportHeader(data);
  if (res.status === 'Success') {
    return res.data;
  }
};

export const onSaveRemedialCertificate = async (data: any) => {
  const res = await createCertificateRemedialReport(data);
  if (res.status === 'Success') {
    return res.data;
  }
};

export const onSaveRemedialSummary = async (data: any) => {
  const res = await createRemedialSummaryReport(data);
  if (res.status === 'Success') {
    return res.data;
  }
};

export const onSaveRemedialActionLog = async (data: any) => {
  const res = await createRemedialActionLogReport(data);
  if (res.status === 'Success') {
    return res.data;
  }
};

export const onSaveRemedialCompetency = async (data: any) => {
  const res = await createRemedialCompetencyReport(data);
  if (res.status === 'Success') {
    return res.data;
  }
};

export const onSaveRemedialFooter = async (data: any) => {
  const res = await createRemedialReportFooter(data);
  if (res.status === 'Success') {
    return res.data;
  }
};

export const onCreateRemedialReport = async (data: any) => {
  const res = await createRemedialInspectionReport(data);
  if (res.status === 'Success') {
    return res.data;
  }
};

export const getBuildingDetails = async (id: string) => {
  buildingByIdArgs.critarion._id = id;
  const response = await getBuilding(buildingByIdArgs);
  return response.data;
};
