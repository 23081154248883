export const SET_OPERATIVES = 'SET_OPERATIVES';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const SET_SCROLL_POS = 'SET_SCROLL_POS';
export const SET_TOTAL_RECORDS = 'SET_TOTAL_RECORDS';

export const actions = {
  setClients: (data: any) => {
    return {
      type: SET_OPERATIVES,
      payload: data
    };
  },
  setPageSize: (data: any) => {
    return {
      type: SET_PAGE_SIZE,
      payload: data
    };
  },
  setScrollPos: (data: any) => {
    return {
      type: SET_SCROLL_POS,
      payload: data
    };
  },
  setTotalRecords: (data: any) => {
    return {
      type: SET_TOTAL_RECORDS,
      payload: data
    };
  }
};

const initialState = {
  rOperatives: [],
  rPageSize: 10,
  rPrevScrollPos: 0,
  rTotalRecords: 0
};

export const reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case SET_OPERATIVES:
      return { ...state, rOperatives: action.payload };
    case SET_PAGE_SIZE:
      return { ...state, rPageSize: action.payload };
    case SET_SCROLL_POS:
      return { ...state, rPrevScrollPos: action.payload };
    case SET_TOTAL_RECORDS:
      return { ...state, rTotalRecords: action.payload };
    default:
      return state;
  }
};
