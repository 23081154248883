import { GetPermissionsByName } from 'hooks/usePermissionAcess';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Chip } from 'primereact/chip';
import { MultiSelect } from 'primereact/multiselect';
import archive from '../../../assets/icons/ri_archive-fill.svg';

interface ChildComponentProps {
  data?: any; // Adjust the type as per your actual data type
  columns?: any[]; // Adjust the type as per your actual columns type
  isLoading?: boolean;
  isShowPaginator?: boolean;
  isShowPagSize?: boolean;
  firstRow?: number;
  rowCount?: number;
  totalRecords?: number;
  onPageChange?: any;
  scrollable?: boolean;
  className?: string;
  dataKey?: string;
  rowSelect?: any;
  selectionMode?: any;
  scrollHeight?: string;
  dataSortMode?: string;
  onSort?: (e: any) => void;
  isShowClientSidePaginator?: boolean;
  filterData?: any[];
  onGlobalFilterChange?: any;
  filterValue?: any;
  sortOrder?: any;
  sortField?: string;
  isShowActionColumns?: boolean;
  isShowClearAllFilter?: boolean;
  isShowViewAction?: boolean;
  isHideEditAction?: boolean;
  isHideArchieveAction?: boolean;
  onView?: (id: string) => void;
  onEdit?: (id: string) => void;
  onArchive?: (id: string) => void;
  isShowPdfAction?: boolean;
  permsissions?: any;
  clearFilterValue?: any;
  onDownload?: (id: string) => void;
  isShowCheckBox?: boolean;
  onSelectionChange?: any;
  selectedData?: [];
  isHideSearch?: boolean;
  onDelete?: (id: string) => void;
  onRestore?: (id: string) => void;
  onFilterDataChange?: (e: any) => void;
  selectedFilter?: any;
}

const CustomDataTable: React.FC<ChildComponentProps> = ({
  data,
  columns,
  isLoading,
  isShowPaginator,
  isShowPagSize,
  firstRow,
  rowCount,
  totalRecords,
  onPageChange,
  scrollable,
  className,
  dataKey,
  rowSelect,
  selectionMode,
  scrollHeight,
  dataSortMode,
  onSort,
  isShowClientSidePaginator,
  filterData = [],
  onGlobalFilterChange,
  filterValue,
  sortOrder,
  sortField,
  isShowActionColumns,
  isShowClearAllFilter,
  isShowViewAction,
  isHideEditAction,
  isHideArchieveAction,
  onView,
  onEdit,
  onArchive,
  isShowPdfAction,
  clearFilterValue,
  onDownload,
  isShowCheckBox,
  onSelectionChange,
  selectedData,
  isHideSearch,
  onDelete,
  onRestore,
  onFilterDataChange,
  selectedFilter
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [emptyMessage, setEmptyMessage] = useState('');

  let user = useSelector((state: any) => {
    return state.auth.user;
  });

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    'country.name': {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    date: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    balance: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const permissionData = useSelector((state: any) => state.auth);
  let moduldePermision = permissionData?.user?.userPersonalPermissions;
  let permsissions: any = GetPermissionsByName('Report Library', moduldePermision);

  useEffect(() => {
    setEmptyMessage('Loading...');
  }, []);

  const bodyTemplate = (rowData: any, key: string, header: any, bodyStyle: any) => {
    if (isLoading) {
      return <Skeleton />;
    } else {
      const style = bodyStyle ? bodyStyle(rowData) : {};
      return <div style={style}>{rowData[`${key}`]}</div>;
    }
  };

  const bodyDataTemplate = (rowData: any, bodyData: any, header: any, bodyStyle: any) => {
    if (isLoading) {
      return <Skeleton />;
    } else {
      const style = bodyStyle ? bodyStyle(rowData) : {};
      return <div style={style}>{bodyData(rowData)}</div>;
    }
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className={`d-flex  align-items-center`}>
        {isShowPdfAction ? (
          <div className='d-flex flex-row gap-2'>
            {user?.operativeTypeEnum === 3 || user?.operativeTypeEnum === 4 ? (
              ''
            ) : (
              <button
                className={`btn btn-icon btn-primary btn-xs btn-light me-2`}
                data-toggle='tooltip'
                title='Edit'
                onClick={() => onEdit && onEdit(rowData._id)}
                disabled={!rowData?.isLegacy}
              >
                <i className='pi pi-pencil'></i>
              </button>
            )}
            {user?.operativeTypeEnum === 3 || user?.operativeTypeEnum === 4 ? (
              ''
            ) : (
              <button
                className='btn btn-icon btn-xs btn-light me-2'
                data-toggle='tooltip'
                title='Remove'
                onClick={() => onArchive && onArchive(rowData._id)}
              >
                <img src={archive} alt='archive' />
                {/* <i className='la la-archive' /> */}
              </button>
            )}
            <button
              type='button'
              className='btn btn-icon btn-xs btn-danger'
              data-toggle='tooltip'
              title='View Report'
              onClick={() => onDownload && onDownload(rowData)}
            >
              <i className='la la-file-pdf-o' />
            </button>

          </div>
        ) : (
          <>
            {isShowViewAction && (
              <button
                className='btn btn-icon btn-xs me-1'
                style={{ backgroundColor: '#FF6600' }}
                data-toggle='tooltip'
                title='view'
                onClick={() => onView && onView(rowData._id)}
              >
                <i className='pi pi-eye'></i>
              </button>
            )}
            {!isHideEditAction && (
              <button
                className='btn btn-icon btn-xs me-1'
                style={{ backgroundColor: '#FF6600' }}
                data-toggle='tooltip'
                title='Edit'
                onClick={() => onEdit && onEdit(rowData._id)}
              >
                <i className='pi pi-pencil'></i>
              </button>
            )}
            {
              (user?.operativeTypeEnum === 3 || user?.operativeTypeEnum === 4) ?
                '' :
                !isHideArchieveAction &&
                <button
                  data-toggle='tooltip'
                  title='Archive'
                  className='btn btn-xs btn-icon btn-secondary me-2'
                  onClick={() => onArchive && onArchive(rowData._id)}
                >
                  <img src={archive} alt='' />
                </button>
            }
            {(user?.operativeTypeEnum === 3 || user?.operativeTypeEnum === 4) ?
              '' : (onDelete && <button
                type='button'
                data-toggle='tooltip'
                title='Delete'
                className='btn btn-xs btn-icon btn-secondary me-2 text-white pi pi-trash'
                onClick={() => onDelete(rowData._id)}
              >
              </button>)
            }
            {onRestore &&
              (
                <button
                  type='button'
                  data-toggle='tooltip'
                  title='Restore'
                  className='btn btn-xs btn-icon btn-primary me-2 text-white pi pi-sync'
                  onClick={() => onRestore(rowData._id)}
                >
                </button>
              )
            }
          </>
        )}
      </div>
    );
  };

  const setDynamicDataTableColumns = () => {
    if (columns && columns.length) {
      if (isShowActionColumns) {
        columns = [
          { field: '', header: 'Action', body: actionBodyTemplate, frozen: true },
          ...columns,
        ];
      }
      if (isShowCheckBox) {
        columns = [...columns, { field: '', header: '', selectionMode: 'multiple' }];
      }
      return columns.map((col, index) => {
        if (col.expander) {
          return (
            <Column
              key={index}
              style={col.style}
              field={col.field}
              header={col.header}
              sortable={col.sortable}
              body={col.body}
              selectionMode={col.selectionMode}
              expander={col.expander}
              rowReorder={col.rowReorder}
              frozen={col.frozen}
              className={col.className}
              headerStyle={col.headerStyle}
              bodyStyle={col.bodyStyle}
            />
          );
        } else {
          return (
            <Column
              key={index}
              style={col.style}
              field={col.field}
              header={col.header}
              sortable={col.sortable}
              body={
                col.body
                  ? (e) => bodyDataTemplate(e, col.body, col.header, col.bodyStyle)
                  : (e) => bodyTemplate(e, col.field, col.header, col.bodyStyle)
              }
              selectionMode={col.selectionMode}
              expander={col.expander}
              rowReorder={col.rowReorder}
              frozen={col.frozen}
              className={col.className}
              headerStyle={col.headerStyle}
              bodyStyle={col.bodyStyle}
            />
          );
        }
      });
    }
  };

  const showPaginator = () => {
    if (isShowPaginator && data?.length > 0) {
      const template = {
        layout:
          'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
        RowsPerPageDropdown: (options: any) => {
          const dropdownOptions = [
            { label: 10, value: 10 },
            { label: 30, value: 30 },
            { label: 'All', value: totalRecords },
          ];

          return (
            <>
              <Dropdown
                id='PagingDropDown'
                value={rowCount}
                options={dropdownOptions}
                onChange={options.onChange}
                appendTo={document.body}
              />
            </>
          );
        },
        CurrentPageReport: (options: any) => {
          return (
            <span
              className='text-muted w-auto'
              style={{
                color: 'var(--text-color)',
                userSelect: 'none',
                width: '185px',
                textAlign: 'center',
              }}
            >
              Showing {options.first} - {options.last} of {options.totalRecords} Records
            </span>
          );
        },
      };
      if (isShowPagSize) {
        return (
          <Paginator
            template={template}
            first={firstRow}
            rows={rowCount}
            totalRecords={totalRecords}
            style={{ opacity: '1' }}
            onPageChange={onPageChange}
            className={isLoading ? `custom-paginator p-disabled` : `custom-paginator`}
          ></Paginator>
        );
      } else {
        return (
          <Paginator
            first={firstRow}
            rows={rowCount}
            totalRecords={totalRecords}
            style={{ opacity: '1' }}
            onPageChange={onPageChange}
            className={isLoading ? `custom-paginator p-disabled` : `custom-paginator`}
          ></Paginator>
        );
      }
    }
  };

  const selectedItemsLabel = (values: any) => {
    const count = values.length - 2;
    if (count > 0) {
      const displayedItems = values.slice(0, 2).map((item: any) => {
        let data = filterData.find((x: any) => item === x.value);
        return <Chip label={data.name} className='me-3 text-dark' />;
      }

      );
      return (
        <div>
          {displayedItems}
          <div className="p-multiselect-token fw-bold">+{count}</div>
        </div>
      );
    } else {
      const displayedItems = values.map((item: any) => {
        let data = filterData.find((x: any) => item === x.value);
        return <Chip label={data.name} className='me-3 text-dark' />;
      });
      return displayedItems;
    }
  };

  const renderHeader = () => {
    return (

      <>

        <div className='d-flex justify-content-end'>
          {filterData?.length ?
            <MultiSelect
              value={selectedFilter}
              onChange={onFilterDataChange}
              options={filterData}
              optionLabel="name"
              placeholder="Select Status"
              maxSelectedLabels={0}
              selectedItemsLabel={selectedItemsLabel(selectedFilter)}
            />
            : <></>
          }
          {!isHideSearch && (
            <div className='ms-5'>
              <span
                className={filterValue ? 'p-input-icon-left' : 'p-input-icon-left'}
              >
                {filterValue ? (
                  <i className='pi pi-times' onClick={clearFilterValue && clearFilterValue} />
                ) : (
                  <i className='pi pi-search' />
                )}

                <InputText
                  value={filterValue}
                  onChange={onGlobalFilterChange}
                  placeholder='Search...'
                />
              </span>
            </div>
          )}
        </div>
      </>


      // <div className='row align-items-center'>
      //   <div className='col-lg-2'>
      //     <MultiSelect
      //       key="value"
      //       value={selectedFilter}
      //       onChange={onFilterDataChange}
      //       options={filterData}
      //       optionLabel="name"
      //       placeholder="Select Status" maxSelectedLabels={1}
      //       className='d-flex'
      //     />

      //   </div>
      //   <div className='col-lg-10'>
      //     {!isHideSearch && (
      //       <div className='search-box-container mx-1 my-3'>
      //         <span
      //           className={filterValue ? 'p-input-icon-left col-lg-2' : 'p-input-icon-left col-lg-2 '}
      //         >
      //           {filterValue ? (
      //             <i className='pi pi-times' onClick={clearFilterValue && clearFilterValue} />
      //           ) : (
      //             <i className='pi pi-search' />
      //           )}

      //           <InputText
      //             value={filterValue}
      //             onChange={onGlobalFilterChange}
      //             placeholder='Search...'
      //           />
      //         </span>
      //       </div>
      //     )}
      //   </div>
      // </div>
    );
  };

  const header = renderHeader();

  return (
    <>
      <div className='commonTableDashboard'>
        <DataTable
          className={`${!scrollable ? 'p-datatable-responsive-commontabledetails' : ''} ${className || 'p-datatable-responsive'
            }`}
          dataKey={dataKey}
          value={data}
          onRowSelect={rowSelect}
          //selectionMode={selectionMode}
          header={header}
          emptyMessage={isLoading ? 'Loading...' : 'No Records Found'}
          scrollable={true}
          scrollHeight={scrollHeight ? scrollHeight : '600px'}
          sortMode={'single'}
          onSort={onSort}
          paginator={isShowClientSidePaginator}
          sortOrder={sortOrder}
          sortField={sortField}
          selectionMode={isShowCheckBox ? null : 'checkbox'}
          selection={selectedData}
          onSelectionChange={onSelectionChange}
        >
          {setDynamicDataTableColumns()}
        </DataTable>
      </div>
      {showPaginator()}
    </>
  );
};

export default CustomDataTable;
